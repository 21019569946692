import {createApp} from 'vue'
import {createPinia} from 'pinia';
import App from './App.vue'
import './assets/css/tailwind.css';
import './assets/css/main.scss';

import './registerServiceWorker'
import router from './router'

const pinia = createPinia();
createApp(App)
    .use(pinia)
    .use(router)
    .mount('#venus-uelzen-pwa')