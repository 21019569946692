<template>
  <navbar-component :search="true" :menu="true"></navbar-component>

  <div class="flex flex-col gap-4 mx-4 pb-4 pt-6 lg:mx-52 xl:mx-96">
    <!-- header -->
    <div class="flex flex-col gap-2 justify-center text-center">
      <div class="font-heading text-3xl font-bold text-secondary">Suche</div>
    </div>

    <!-- listing -->
    <listing-component :filter="filterLink" :sort="false"></listing-component>
  </div>

  <footer-component></footer-component>
</template>

<script>
import NavbarComponent from "@/components/NavbarComponent.vue";
import ListingComponent from "@/components/ListingComponent.vue";
import FooterComponent from "@/components/FooterComponent.vue";

export default {
  name: "ListingView",
  components: {
    FooterComponent,
    NavbarComponent,
    ListingComponent,
  },
  data() {
    return {
      filterLink: undefined
    }
  },
  created() {
    const query = new URLSearchParams(window.location.search).toString();

    if (query)
      this.filterLink = query;
  },
  watch: {
    $route(value) {
      this.filterLink = new URLSearchParams(value.fullPath).toString();
    }
  }
}
</script>

<style scoped>

</style>