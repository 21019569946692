<template>
  <navbar-component :search="true" :menu="true"></navbar-component>

  <div class="flex flex-col gap-4 mx-4 pb-4 lg:mx-52 xl:mx-96 pt-6">
    <!-- header -->
    <div class="flex flex-col gap-2 justify-center text-center">
      <div class="text-xl uppercase text-gray-600">Die besten Ausflugstipps</div>
      <div class="font-heading text-3xl font-bold text-secondary">
        <!--Sehenswertes-->
        {{ getFilterName() }}
      </div>
    </div>

    <div class="flex flex-row gap-2 justify-around md:justify-center">
      <!--
      <div class="flex flex-col items-center gap-2" v-for="(filter, index) of filters" :key="index">
        <button class="rounded-full p-4 w-14 h-14 flex flex-row justify-center items-center cursor-pointer"
                :class="`${filter.filter === currentFilter ? 'bg-primary' : 'bg-[#98538D7F]'}`"
                @click="currentFilter = filter.filter"
                :title="filter.name"
        >
          <i :class="`${filter.icon} ${filter.filter === currentFilter ? 'text-white' : 'text-primary'}`" class="fa-xl"></i>
        </button>

        <p class="text-black" v-if="filter.filter === currentFilter"> {{ getFilterName()}}</p>
      </div>
      -->

      <button v-for="(filter, index) of filters" :key="index"
              class="rounded-full p-4 w-14 h-14 flex flex-row justify-center items-center cursor-pointer"
              :class="`${filter.filter === currentFilter ? 'bg-primary' : 'bg-[#98538D7F]'}`"
              @click="currentFilter = filter.filter"
              :title="filter.name"
      >
        <i :class="`${filter.icon} ${filter.filter === currentFilter ? 'text-white' : 'text-primary'}`"
           class="fa-xl"></i>
      </button>
    </div>

    <!-- listing -->
    <listing-component :filter="currentFilter"></listing-component>
  </div>

  <footer-component></footer-component>
</template>

<script>
import NavbarComponent from "@/components/NavbarComponent.vue";
import ListingComponent from "@/components/ListingComponent.vue";
import FooterComponent from "@/components/FooterComponent.vue";

export default {
  name: "AttractionsView",
  components: {
    FooterComponent,
    NavbarComponent,
    ListingComponent
  },
  data() {
    return {
      filters: [
        {
          name: 'Sehenswertes',
          icon: 'fa-solid fa-map-marked-alt',
          filter: '/topic=sight'
        },
        {
          name: 'Freizeitaktivitäten',
          icon: 'fa-solid fa-umbrella-beach',
          filter: '/type=Airport:BoatRental:BowlingAlley:CanoeRental:Fishing:GolfCourse:Minigolf:MovieTheater:PerformingArtsTheater:Playground:PublicSwimmingPool&topics=bathing_lake:bathing_possibilities:carriage_ride:nutztiersafari:free_time:outdoor_swimming_pool',
        },
        {
          name: 'Museen',
          icon: 'fa-solid fa-landmark',
          filter: '/type=Museum',
        },
        /*
        {
          name: 'Freizeitaktivitäten',
          icon: 'fa-solid fa-water-ladder',
          filter: '/topic=free_time',
        },
        {
          name: 'Sehenswertes',
          icon: 'fa-solid fa-monument',
          filter: '/topic=sight',
        },
        */
        {
          name: 'Kirchen & Klöster',
          icon: 'fa-solid fa-church',
          filter: '/type=Church:Monastery',
        },
        {
          name: 'Einkaufen',
          icon: 'fas fa-shopping-bag',
          filter: '/type=Store:GroceryStore:ShoppingCenter:ClothingStore:BikeStore:FarmShop:Butchery',
        },
      ],
      currentFilter: ''
    }
  },
  created() {
    this.currentFilter = this.filters[0].filter;
  },
  methods: {
    getFilterName() {
      return this.filters.find(filter => filter.filter === this.currentFilter).name;
    }
  }
}
</script>

<style scoped>

</style>