<template>
  <!-- nav -->
  <navbar-component :search="true" :menu="true"></navbar-component>

  <!-- pt-[116px] -->
  <div class="flex flex-col gap-4 mx-4 pb-4 pt-16 lg:mx-52 xl:mx-96">
    <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
      <div v-for="(card, index) of cards" :key="index"
           class="bg-primary hover:bg-secondary cursor-pointer flex flex-col gap-4 rounded-2xl p-4 items-center justify-center"
           @click="$router.push(card.path)">
        <!-- icon -->
        <div>
          <i :class="card.icon" class="text-[3.5em] md:text-[4em]"></i>
        </div>
        <!-- title -->
        <div class="font-heading font-bold text-3xl md:text-4xl">
          <p class="break-all">{{ card.name }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NavbarComponent from "@/components/NavbarComponent.vue";

export default {
  name: "ToursView",
  components: {NavbarComponent},
  data() {
    return {
      cards: [
        {
          name: 'Radfahren',
          path: '/tours/biking',
          icon: 'fa-solid fa-person-biking'
        },
        {
          name: 'Wandern',
          path: '/tours/hiking',
          icon: 'fa-solid fa-person-hiking'
        }
      ]
    }
  }
}
</script>

<style scoped>

</style>