<template>
  <navbar-component :search="true" :menu="true"></navbar-component>

  <div class="flex flex-col gap-4 mx-4 pb-4 pt-6 lg:mx-52 xl:mx-96 text-black text-center">
    <p>
      <strong class="font-heading">Herausgeber und für den Inhalt verantwortlich:</strong>
    </p>
    <p>HeideRegion Uelzen e.V.<br> Herzogenplatz 2<br> 29525 Uelzen</p>
    <p>Tel.: +49 (0) 581 / 7 30 40<br> Fax: +49 (0) 581 / 7 23 84</p>
    <p>
      <a href="mailto:info@heideregion-uelzen.de"
         title="E-Mail-Adresse:info@heideregion-uelzen.de" class="email underline">info@heideregion-uelzen.de</a><br>
      <a href="https://www.heideregion-uelzen.de/" title="Neues Fenster: http://www.heideregion-uelzen.de"
         target="_blank" class="underline">http://www.heideregion-uelzen.de</a>
    </p>
    <p>&nbsp;</p>
    <p>
      <strong class="font-heading">Vertretungsberechtigter Vorstand:</strong>
      <br> Michael Müller (1. Vorsitzender)<br>
      Martin Feller (stellv. Vorsitzender)<br> <br>
      <strong class="font-heading">Eintragung im Vereinsregister:</strong><br>
      Amtsgericht Lüneburg, VR 140068
    </p>
    <p>&nbsp;</p>
    <p><strong class="font-heading">Design, technische Konzeption und Umsetzung:</strong></p>
    <p><strong class="font-heading">Venus GmbH</strong></p>
    <p class="text-center">Elsa-Brändström-Str. 2<br> 94327 Bogen<br> Tel. 09422 8506-0<br> Fax 09422 8506-30<br> <a
        href="mailto:info@venus.bayern" title="info@venus.bayern" class="underline">info@venus.bayern</a><br> <a
        href="http://www.venus.bayern" title="www.venus.bayern" target="_blank" rel="noreferrer" class="underline">www.venus.bayern</a>
    </p>
    <p>&nbsp;</p>
    <p><strong class="font-heading">Datenschutz</strong></p>
    <p>
      Bitte beachten Sie unsere
      <router-link to="/privacy" class="underline">Datenschutzhinweise</router-link>
    </p>
    <p>&nbsp;</p>
    <p>&nbsp;</p>
    <p class="content"><strong class="font-heading">Disclaimer, Copyright</strong><br> <br> Durch den Zugriff auf diese
      Website erkennen Sie
      die nachfolgenden Bedingungen und alle anwendbaren Gesetze uneingeschränkt an. Wenn nicht anderweitig geregelt,
      sind die Inhalte dieser Website - einschließlich der darin enthaltenen Texte und Abbildungen und deren Anordnung -
      Eigentum der HeideRegion Uelzen e.V. Alle eingetragenen Warenzeichen, Logos und Marken, die auf dieser Website
      verwendet werden, sind Eigentum der jeweiligen Inhaber. Die HeideRegion Uelzen e.V. erklärt, dass sie alle
      Eigentumskennzeichnungen als solche anerkennt, auch wenn im Einzelnen ein solcher Hinweis fehlt.<br> <br> Das
      Copyright und die Nutzungsrechte für alle in der Präsentation verwendeten Grafiken, Fotografien und Texte besitzt
      die HeideRegion Uelzen e.V. oder sind ihr zur Nutzung übertragen worden, eine weitere Verwendung ist ohne
      ausdrückliche Genehmigung nicht erlaubt.<br> <br> Die Daten und Texte, die auf dieser Website zur Verfügung
      gestellt werden, dienen ausschließlich Informationszwecken. Die Angaben beruhen auf den von den Orten,
      Institutionen oder Einzelpersonen zur Verfügung gestellten Informationen. Für deren Vollständigkeit und
      Richtigkeit übernimmt die HeideRegion Uelzen e.V. keine Gewähr oder Haftung. Eine Nutzung der Informationen zieht
      keine geschäftliche Bindung zwischen dem Besucher der Website und der HeideRegion Uelzen e.V. nach sich.
      Hyperlinks auf dieser Website können zu anderen, externen Websites führen, die nicht von der HeideRegion Uelzen
      e.V. gepflegt werden. Für den Inhalt dieser Seiten übernimmt die HeideRegion Uelzen e.V. keine Haftung oder
      Verantwortung. Ein Link zu einer externen Website ist keine Garantie für deren Inhalte oder deren angebotene
      Leistungen. Jegliche Nutzung der Informationen, die auf der Website zur Verfügung stehen, oder auf anderen
      Websites, die mit ihr verlinkt sind, geschieht auf eigenes Risiko. Die HeideRegion Uelzen e.V. distanziert sich
      ausdrücklich von den Inhalten externer Websites.<br> <br> Die HeideRegion Uelzen e.V. betreibt diese Website in
      Deutschland. Den Nutzungsbedingungen und sämtlichen Rechtsverfahren liegt das deutsche Recht zugrunde. Sollte ein
      Gerichtsverfahren gegen die HeideRegion Uelzen e.V. geführt werden, liegt die Entscheidung auf Seiten der
      HeideRegion Uelzen e.V., welche Gerichtsbarkeit ausgewählt wird. Die HeideRegion Uelzen e.V. wird mit hoher
      Wahrscheinlichkeit als Gerichtsstandort Deutschland auswählen, unabhängig davon, in welchem Land Sie sich befinden
      oder von welchem Land Sie die Website besucht haben.<br> <br> <br> <strong
          class="font-heading">Angebote</strong><br> <br> Für die
      Richtigkeit und Vollständigkeit von Preisen, Reisezeiten und Angeboten von Drittanbietern, die auf der Website der
      HeideRegion Uelzen e.V. dargestellt sind, sind die jeweiligen Anbieter verantwortlich. Die HeideRegion Uelzen e.V.
      tritt weder als Reisemittler noch Reiseveranstalter auf, sondern bietet seinen Partnern und touristischen
      Leistungsträgern das Internetportal lediglich als Plattform zur Darstellung des jeweiligen Angebotes an. Alle
      Angaben erfolgen deshalb ohne Gewähr.<br> <br> Bitte beachten Sie, dass bei der Vermittlung einer Pauschale die
      allgemeinen Geschäftsbedingungen (AGB) des Anbieters gelten. Diese werden Ihnen i.d.R. vor Abschluss des
      Reisevertrages durch die Informations- und Buchungsstelle oder den Anbieter zugesandt. Der Anbieter ist im Sinne
      des § 651 BGB Reiseveranstalter und hat die daraus abzuleitenden gesetzlichen Bestimmungen einzuhalten.</p>
    <p class="content">&nbsp;</p>
    <p><strong class="font-heading">Pflichtinformation nach der Verordnung (EU) Nr. 524/2013 des Europäischen Parlaments
      und Rats:</strong>
    </p>
    <p>Link zur Homepage der Stelle für die Online-Beilegung verbraucherrechtlicher Streitigkeiten der Europäischen
      Kommission: <a href="http://ec.europa.eu/consumers/odr/" target="_blank" rel="noreferrer" class="underline">http://ec.europa.eu/consumers/odr/</a>
      – weiterführende Informationen stehen Ihnen dort voraussichtlich ab dem 15.02.2016 zur Verfügung. Für erste Fragen
      zu einer möglichen Streitschlichtung kontaktieren Sie uns bitte unter <a href="mailto:info@heideregion-uelzen.de"
                                                                               class="underline">info@heideregion-uelzen.de</a>
    </p>
    <p>&nbsp;</p>
    <p class="content">&nbsp;</p>
  </div>
</template>

<script>
import NavbarComponent from "@/components/NavbarComponent.vue";

export default {
  name: "ImprintView",
  components: {
    NavbarComponent
  }
}
</script>

<style scoped>

</style>