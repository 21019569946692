<template>
  <!-- nav -->
  <navbar-component :search="true" :menu="true"></navbar-component>

  <div class="flex flex-col gap-4 mx-4 pb-4 pt-6 lg:mx-52 xl:mx-96">
    <!-- header -->
    <div class="flex flex-col gap-2 justify-center text-center">
      <div class="text-xl uppercase text-gray-600">Die Heideregion erleben</div>
      <div v-if="currentFilter === defaultFilter" class="font-heading text-3xl font-bold text-secondary">Umgebung
        entdecken
      </div>
      <div v-else class="font-heading text-3xl font-bold text-secondary">{{ getFilterName() }}</div>
    </div>

    <!-- map -->
    <!-- filter -->
    <div class="flex flex-row gap-2 justify-around md:justify-center">
      <!--
      <button v-for="(filter, index) of filters" :key="index"
              class="rounded-full p-4 w-14 h-14 flex flex-row justify-center items-center cursor-pointer"
              :style="`background-color: ${filter.colors.background}`"
              @click="fetchByFilter(filter.filter)"
              :title="filter.name"
      >
        <span v-if="currentFilter === filter.filter">
            <i class="fa-solid fa-xmark fa-xl" :style="`color: ${filter.colors.icon}`"></i>
        </span>
        <span v-else>
            <i :class="filter.icon" class="fa-xl" :style="`color: ${filter.colors.icon}`"></i>
        </span>
      </button>
      -->

      <button v-for="(filter, index) of filters" :key="index"
              class="rounded-full p-4 w-14 h-14 flex flex-row justify-center items-center cursor-pointer"
              :style="`background-color: ${currentFilter === filter.filter ? filter.colors.active : filter.colors.background}`"
              @click="fetchByFilter(filter.filter)"
              :title="filter.name"
      >
        <span v-if="currentFilter === filter.filter">
          <i :class="filter.icon" class="fa-xl text-white"></i>
        </span>
        <span v-else>
            <i :class="filter.icon" class="fa-xl" :style="`color: ${filter.colors.icon}`"></i>
        </span>
      </button>
    </div>
  </div>

  <!-- map canvas -->
  <div class="flex flex-row h-full items-center">
    <div id="venus-map" class="w-screen h-[28rem] md:h-[40rem]"></div>
  </div>

  <footer-component></footer-component>
</template>

<script>
import NavbarComponent from "@/components/NavbarComponent.vue";
import FooterComponent from "@/components/FooterComponent.vue";
import {VenusMap} from '@venus-gmbh/map';

export default {
  name: "MapView",
  components: {FooterComponent, NavbarComponent},
  data() {
    return {
      loading: false,
      filters: [
        {
          name: 'Unterkünfte',
          icon: 'fa-solid fa-bed',
          filter: '/?time=now,14d,true&type=LodgingBusiness',
          colors: {
            background: 'rgb(193,88,88, 0.5)',
            active: '#681C1C',
            icon: '#681C1C'
          }
        },
        {
          name: 'Gastronomie',
          icon: 'fa-solid fa-fork-knife',
          filter: '/?time=now,14d,true&type=FoodEstablishment',
          colors: {
            background: 'rgba(206,145,29,0.5)',
            active: '#FF7F00',
            icon: '#FF7F00'
          }
        },
        {
          name: 'Wandern',
          icon: 'fa-solid fa-person-hiking',
          filter: '/?time=now,14d,true&type=HikingTrail:WinterhikingTrail',
          colors: {
            background: 'rgba(88,152,83,0.5)',
            active: '#2B6501',
            icon: '#2B6501'
          }
        },
        {
          name: 'Radfahren',
          icon: 'fa-solid fa-person-biking',
          filter: '/?time=now,14d,true&type=CyclingTrail:BikeTrail:RacingBikeTrail',
          colors: {
            background: 'rgba(88,152,83,0.5)',
            active: '#2B6501',
            icon: '#2B6501'
          }
        },
        {
          name: 'Attraktionen',
          icon: 'fa-solid fa-landmark',
          filter: '/?time=now,14d,true&type=TouristAttraction:CivicStructure',
          colors: {
            background: '#98538D7F',
            active: '#98538D',
            icon: '#681C5C'
          }
        }
      ],
      currentFilter: undefined,
      defaultFilter: undefined,
      map: undefined
    }
  },
  mounted() {
    this.render();
  },
  methods: {
    getFilterName() {
      return this.filters.find(filter => filter.filter === this.currentFilter)?.name;
    },
    async render() {
      this.defaultFilter = '/?time=now,14d,true&type=!ContactPoint';

      this.map = new VenusMap({
        debug: false,
        container: 'venus-map',
        style: 'https://tiles.venus.bayern/styles/venus-basic/style.json',
        center: [10.4541194, 51.1642292],
        pitch: 0,
        bearing: 0,
        zoom: 7,
        attributionControl: false,
        trackResize: true,

        data: {
          projects: ['heideregion-uelzen', 'erlebniswelt-lueneburger-heide'],
          channels: ['377'],
          filter: this.defaultFilter
        },
        appearance: {
          primary: '#98538D',
          secondary: '#98538D',
          views: {
            entity: {
              description: false
            }
          }
        },
        overlay: {
          linkBehavior: 'sameSite',
          sidebar: {
            nearbyFilters: false,
            search: false
          },
          controls: {
            zoom: true,
            resetNorth: true,
            geoLocate: {
              trackUserLocation: true,
              showUserLocation: true
            },
          },
          styles: false,
          logo: true
        },
        elevationProfile: false
      });

      await this.fetchByFilter('/?time=now,14d,true');
    },
    async fetchByFilter(filterLink) {
      if (this.currentFilter === filterLink) {
        await this.map.fetchByFilter(this.defaultFilter);
        this.currentFilter = this.defaultFilter;
        return;
      }

      this.currentFilter = filterLink;
      await this.map.fetchByFilter(filterLink);
    }
  }
}
</script>

<style scoped>

</style>
