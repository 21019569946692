<template>
  <div
      class="fixed bottom-0 h-16 md:h-18 w-full bg-primary text-white grid grid-cols-3 gap-2 items-center justify-items-center px-8">
    <!-- router back -->
    <div class="justify-self-center flex-grow">
      <button title="Zurück zur vorherigen Seite" class="flex flex-col items-center">
        <i class="fa-solid fa-arrow-left text-2xl" @click="$router.go(-1)"></i>
        <span class="text-sm">Zurück</span>
      </button>
    </div>

    <!-- bookmarks -->
    <div
        class="h-full px-4 flex flex-col flex-grow items-center justify-center w-full"
        :class="{'bg-secondary': $route.name === 'bookmarks'}"
    >
      <button v-if="$route.name === 'bookmarks'" title="Zur Merkliste" class="flex flex-col justify-center items-center">
        <i class="fa-solid fa-heart text-2xl md:text-3xl text-white cursor-pointer"
           @click="$router.push('/bookmarks')"></i>
        <span class="text-sm">Merkliste</span>
      </button>
      <button v-else title="Zur Merkliste" class="flex flex-col justify-center items-center">
        <i class="fa-regular fa-heart text-2xl md:text-3xl text-white cursor-pointer"
           @click="$router.push('/bookmarks')"></i>
        <span class="text-sm">Merkliste</span>
      </button>
    </div>

    <!-- page beginning -->
    <div v-if="$route.name !== 'bookmarks'" class="justify-self-center flex-grow">
      <button title="Zum Seitenanfang navigieren" class="flex flex-col items-center">
        <i class="fa-solid fa-arrow-up text-2xl" @click="scrollToTop()"></i>
        <span class="text-sm">Nach oben</span>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "FooterComponent",
  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    }
  }
}
</script>

<style scoped>

</style>