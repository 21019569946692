import {defineStore} from 'pinia';
import axios from "axios";

export const useDataStore = defineStore({
    id: 'data',
    state: () => ({
        fetched: []
    }),
    getters: {},
    actions: {
        async fetchByFilter(filterLink) {
            const alreadyFetched = this.fetched[filterLink];
            if (alreadyFetched)
                return alreadyFetched;

            // add filter
            const parsedFilterLink = new URLSearchParams(filterLink);
            parsedFilterLink.set('projects', 'heideregion-uelzen:erlebniswelt-lueneburger-heide');
            parsedFilterLink.set('channels', '377');

            const entities = await axios.post('https://gateway.venus.bayern/api/v1/entities', {
                "filter": {
                    "type": "filter-link",
                    "content": parsedFilterLink.toString()
                },
                "result": {
                    "format": "json",
                    "properties": ['name', 'address', 'MD_public', 'shortDescription', 'description', 'sort'],
                    "transform": {
                        "language": "de-DE"
                    }
                },
                "options": {
                    "size": 500
                }
            });

            this.fetched[filterLink] = entities.data;
            return entities.data;
        }
    }
});
