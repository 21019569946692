import {createRouter, createWebHistory} from 'vue-router'
import HomeView from '../views/HomeView.vue'
import MapView from "@/views/MapView.vue";
import AttractionsView from "@/views/AttractionsView.vue";
//import RoundTripView from "@/views/RoundTripView.vue";
import ToursView from "@/views/ToursView.vue";
import BlankView from "@/views/BlankView.vue";
import GastronomyView from "@/views/GastronomyView.vue";
import AccommodationsView from "@/views/AccommodationsView.vue";
import ImprintView from "@/views/ImprintView.vue";
import DetailView from "@/views/DetailView.vue";
import ListingView from "@/views/ListingView.vue";
import BookmarksView from "@/views/BookmarksView.vue";
import RoundTripView from "@/views/RoundTripView.vue";
import PrivacyView from "@/views/PrivacyView.vue";

const routes = [
    {
        path: '/',
        name: 'home',
        component: HomeView
    },

    // Map
    {
        path: '/map',
        name: 'map',
        component: MapView
    },
    // Attractions
    {
        path: '/attractions',
        name: 'attractions',
        component: AttractionsView
    },
    // Tours
    {
        path: '/tours',
        component: BlankView,
        children: [
            {
                path: '',
                name: 'tours',
                component: ToursView
            },
            {
                name: 'tours.biking',
                path: 'biking',
                component: function () {
                    return import('../views/tours/BikingTourView.vue')
                }
            },
            {
                name: 'tours.hiking',
                path: 'hiking',
                component: function () {
                    return import('../views/tours/HikingTourView.vue')
                }
            }
        ]
    },
    // Round trip
    {
        path: '/round-trip',
        name: 'round-trip',
        component: RoundTripView
        //redirect: () => {
            // the function receives the target route as the argument
            // we return a redirect path/location here.
        //    return { path: '/detail/6318701e4b9257059b57aaff' }
        //},
    },
    // Gastronomy
    {
        path: '/gastronomy',
        name: 'Gastronomy',
        component: GastronomyView
    },
    // Accommodations
    {
        path: '/accommodations',
        name: 'accommodation',
        component: AccommodationsView
    },
    // Imprint
    {
        path: '/imprint',
        name: 'imprint',
        component: ImprintView
    },
    // privacy
    {
        path: '/privacy',
        name: 'privacy',
        component: PrivacyView
    },
    // Listing
    {
        path: '/listing:pathMatch(.*)*',
        name: 'listing',
        component: ListingView
    },
    // Listing
    {
        path: '/detail/:id',
        name: 'detail',
        props: true,
        component: DetailView
    },
    // Bookmarks
    {
        path: '/bookmarks',
        name: 'bookmarks',
        component: BookmarksView
    },
    // Add to device
    /*
    {
        path: '/add-to-device',
        name: 'add-to-device',
        component: AddToDeviceView
    },
     */
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
