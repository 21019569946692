<template>
  <nav class="flex flex-row items-center justify-center md:px-8">
    <div class="flex-grow flex flex-row w-[100px] text-center md:text-left">
      <div v-if="menu" class="flex-grow">
        <i class="fa-solid fa-bars text-[2em] md:text-[2.5em] text-primary cursor-pointer"
           @click="$router.push('/')"></i>
      </div>
    </div>
    <div class="flex-grow cursor-pointer" @click="$router.push('/')">
      <img src="https://www.heideregion-uelzen.de/fileadmin/pwa/heideregion-uelzen_logo_rund.png" alt="Heideregion Uelzen PWA"
           class="w-[235px] md:w-[265px] h-auto mx-auto">
    </div>
    <div class="flex-grow flex flex-row w-[100px] text-center md:text-right">
      <div v-if="search" class="flex-grow text-primary">
        <i class="fa-solid fa-magnifying-glass text-[2em] md:text-[2.5em] hover:text-secondary cursor-pointer"
           @click="searchDialog = !searchDialog"></i>
      </div>
    </div>

    <!-- search container -->
    <div v-show="searchDialog"
         class="absolute top-0 bg-primary sm:rounded-b-2xl p-4 w-screen sm:w-1/2 md:w-1/2 lg:w-1/3 xl:w-1/3 2xl:w-1/4 flex flex-col gap-4">
      <!-- close dialog -->
      <div>
        <i class="fa-solid fa-xmark text-[2.5em] cursor-pointer" @click="searchDialog = false"></i>
      </div>
      <!-- search input -->
      <div class="relative">
        <input
            class="py-2 text-primary placeholder-primary p-4 rounded-md focus:outline-none focus:bg-white w-full"
            placeholder="Suche..."
            autocomplete="off"
            v-model="searchValue"
            @keyup.enter="emitSearch()">

        <span class="absolute inset-y-0 right-0 flex items-center pl-2" @click="emitSearch()">
            <i class="fa-solid fa-magnifying-glass fa-lg pr-2 text-primary cursor-pointer"></i>
        </span>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: "NavbarComponent",
  props: [
    'search',
    'menu'
  ],
  data() {
    return {
      searchDialog: false,
      searchValue: ''
    }
  },
  methods: {
    emitSearch() {
      this.$router.push(`/listing?query=${this.searchValue}`);
      this.searchDialog = false;
    }
  }
}
</script>

<style scoped>

</style>