<template>
  <div v-if="!loading" class="flex flex-col gap-4 mb-8">
    <div class="text-sm text-gray-500">{{ entities.length }} Ergebnisse</div>
    <div v-for="(entity, index) of entities" :key="index" class="flex flex-col md:flex-row gap-4 py-4"
         :class="{'border-b border-gray-300': index !== entities.length - 1}">
      <a class="min-w-[270px] h-[180px] overflow-hidden"
         :href="`/detail/${entity.id}`"
      >
        <img v-if="entity.thumbnail" :src="entity.thumbnail" :alt="entity.name"
             class="w-full h-full object-cover object-center" style="width: 270px; height: 180px">
      </a>

      <div class="flex flex-col flex-grow">
        <!-- name -->
        <div class="text-lg text-primary font-bold">
          <a :href="`/detail/${entity.id}`">{{ entity.name }}</a>
        </div>

        <!-- distance -->
        <div v-if="entity.distance !== 0" class="text-black text-sm pb-2">
          ca. <span class="text-primary">{{ entity.distance }} km</span> Luftlinie entfernt
        </div>

        <!-- address -->
        <div class="flex flex-row text-xs text-black">
          <i class="fa-sharp fa-solid fa-location-dot pr-1"></i> {{ entity.address }}
        </div>

        <!-- short description -->
        <div class="text-sm text-black pt-2">
          <div v-html="entity.shortDescription"></div>
        </div>
      </div>
    </div>

    <!-- no results
    <div v-if="entities.length === 0" class="flex-grow mt-12 text-center text-black text-xl">
      <p>Keine Ergebnisse gefunden...</p>
    </div>
    -->
  </div>
  <div v-else class="flex flex-col gap-2 ">
    <div v-for="(index) of [1,2,3,4,5]" :key="index" class="rounded-md py-4 snap-start">
      <div class="flex flex-row flex-grow gap-4 animate-pulse">
        <div class="bg-gray-300 flex-grow rounded-md w-[270px] h-[180px]">
        </div>

        <div class="flex flex-col gap-2 flex-grow break-words w-full">
          <div class="bg-gray-300 rounded-md h-5">
          </div>

          <div class="bg-gray-300 w-60 rounded-md h-3">
          </div>

          <div class="bg-gray-300 rounded-md h-20">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {useDataStore} from "@/stores/data";
import {mapStores} from "pinia";

export default {
  name: "ListingComponent",
  props: {
    filter: {
      required: true,
      type: String
    },
    sort: {
      required: false,
      default: true,
      type: Boolean
    }
  },
  data() {
    return {
      loading: false,
      entities: []
    }
  },
  computed: {
    ...mapStores(useDataStore)
  },
  watch: {
    filter(value) {
      this.fetchByFilter(value);
    }
  },
  async mounted() {
    if (this.filter)
      await this.fetchByFilter(this.filter);
  },
  methods: {
    async fetchByFilter(filter) {
      this.loading = true;

      const parsedFilterLink = new URLSearchParams(filter);
      if (this.sort !== false) {
        const latLng = await this.fetchCurrentPos(true);
        if (latLng)
          parsedFilterLink.set('sort', `distance,0,${latLng.lat},${latLng.lng}`);
      }

      const data = (await this.dataStore.fetchByFilter(parsedFilterLink.toString())).data;

      const processed = [];
      for (const rawEntity of data) {
        let entity = {
          id: rawEntity._id,
          name: rawEntity.name.find((entry) => entry.lang === 'de-DE')?.value ?? "",
          shortDescription: this.truncate((rawEntity.shortDescription ?? rawEntity.description)?.find((description) => description.lang === "de-DE")?.value ?? undefined, 200),
          address: "",
          distance: !rawEntity.sort[0] || isNaN(rawEntity.sort[0]) ? 0 : parseFloat(rawEntity.sort[0]).toFixed(2)
        }

        for (const image of rawEntity?.MD_public ?? []) {
          if (!image.fileUsage?.includes('thumbnail'))
            continue;

          const thumbUrl = image.media?.thumbUrl;
          if (!thumbUrl)
            continue;

          entity.thumbnail = 'https://webimages.we2p.de/2/' + thumbUrl;
        }

        if (!entity.thumbnail && rawEntity.MD_public && rawEntity.MD_public[0]?.thumbUrl)
          entity.thumbnail = 'https://webimages.we2p.de/2/' + rawEntity?.MD_public[0]?.thumbUrl;

        // use default
        if (!entity.thumbnail)
          entity.thumbnail = 'https://webimages.we2p.de/static/we2p_web_modules/content.php?type=types&value=Organization&project=heideregion-uelzen';

        if (rawEntity.address) {
          if (rawEntity.address.street)
            entity.address += rawEntity.address.street;

          if (rawEntity.address.zip)
            entity.address += (entity.address.length > 0 ? ', ' : '') + rawEntity.address.zip.replace(/\s+/g, '')

          if (rawEntity.address.city)
            entity.address += (entity.address.length > 0 ? ' ' : '') + rawEntity.address.city
        }

        processed.push(entity);
      }

      this.entities = processed;
      this.loading = false;
    },
    truncate(text, stop, clamp) {
      if (!text)
        return "";

      return text.slice(0, stop) + (stop < text.length ? clamp || '...' : '')
    },
    async fetchCurrentPos(asLatLng = false) {
      const format = {
        lat: 0,
        lng: 0
      }

      return new Promise((resolve) => {
        navigator.geolocation.getCurrentPosition(position => {
          if (!position) {
            resolve(undefined);
            return;
          }

          if (asLatLng) {
            format.lat = position.coords.latitude;
            format.lng = position.coords.longitude;
            resolve(format);
          } else {
            resolve([position.coords.latitude, position.coords.longitude]);
          }
        }, () => {
          resolve(undefined);
        });
      });
    },
  }
}
</script>

<style scoped>

</style>