<template>
  <!-- nav -->
  <navbar-component :search="true" :menu="true"></navbar-component>

  <div v-if="false" class="flex flex-col gap-4 mx-4 pb-4 lg:mx-52 xl:mx-96">
    <!-- header -->
    <div class="flex flex-col gap-2 justify-center text-center">
      <div class="text-xl uppercase text-gray-600">Gespeichert für später</div>
      <div class="font-heading text-3xl font-bold text-secondary">Merkliste</div>
    </div>
  </div>

  <div class="flex flex-col gap-4 mx-4 pb-4 lg:mx-52 xl:mx-96 pt-6">
    <iframe id="venus-module__boc_bookmarks"
            width="100%"
            class="h-screen"
            src="https://module-pwa.heideregion-uelzen.de/bookmarks"
    ></iframe>
  </div>

  <footer-component></footer-component>
</template>

<script>
import NavbarComponent from "@/components/NavbarComponent.vue";
import FooterComponent from "@/components/FooterComponent.vue";

export default {
  name: "BookmarksView",
  components: {FooterComponent, NavbarComponent}
}
</script>

<style scoped>

</style>