<template>
  <navbar-component :search="true" :menu="true"></navbar-component>

  <div class="flex flex-col gap-4 mx-4 pb-4 pt-6 lg:mx-52 xl:mx-96 text-black text-center">
    <strong class="font-heading">Datenschutz­hinweise</strong>
    <h2 class="font-bold">Einleitung</h2>
    <p>Mit den folgenden Informationen möchten wir Ihnen als „betroffener Person“ einen Überblick über die Verarbeitung
      Ihrer personenbezogenen Daten durch uns und Ihre Rechte aus den Datenschutzgesetzen geben. Eine Nutzung unserer
      Internetseiten ist grundsätzlich ohne Eingabe personenbezogener Daten möglich. Sofern Sie besondere Services
      unseres Unternehmens über unsere Internetseite in Anspruch nehmen möchten, könnte jedoch eine Verarbeitung
      personenbezogener Daten erforderlich werden. Ist die Verarbeitung personenbezogener Daten erforderlich und besteht
      für eine solche Verarbeitung keine gesetzliche Grundlage, holen wir generell eine Einwilligung von Ihnen ein.</p>
    <p>Die Verarbeitung von personenbezogenen Daten, beispielsweise Ihres Namens, der Anschrift oder E-Mail-Adresse,
      erfolgt stets im Einklang mit der Datenschutz-Grundverordnung (DS-GVO) und in Übereinstimmung mit den für die
      „JF.Consulting GmbH“ geltenden landesspezifischen Datenschutzbestimmungen. Mittels dieser Datenschutzerklärung
      möchten wir Sie über Umfang und Zweck der von uns erhobenen, genutzten und verarbeiteten personenbezogenen Daten
      informieren.</p>
    <p>Wir haben als für die Verarbeitung Verantwortliche zahlreiche technische und organisatorische Maßnahmen
      umgesetzt, um einen möglichst lückenlosen Schutz der über diese Internetseite verarbeiteten personenbezogenen
      Daten sicherzustellen. Dennoch können internetbasierte Datenübertragungen grundsätzlich Sicherheitslücken
      aufweisen, sodass ein absoluter Schutz nicht gewährleistet werden kann. Aus diesem Grund steht es Ihnen frei,
      personenbezogene Daten auch auf alternativen Wegen, beispielsweise telefonisch oder per Post, an uns zu
      übermitteln.</p>
    <strong class="font-heading">Verantwortliche</strong>
    <h3 class="font-bold">Hinweis zur verantwortlichen Stelle</h3>
    <p>Die verantwortliche Stelle für die Datenverarbeitung auf dieser Website ist:</p>
    <p>HeideRegion Uelzen e.V.</p>
    <p>Herzogenplatz 2</p>
    <p>29525 Uelzen</p>
    <p>Tel.: +49 (0) 581 / 7 30 40</p>
    <p><a href="mailto:info@heideregion-uelzen.de" title="E-Mail-Adresse:info@heideregion-uelzen.de">info@heideregion-uelzen.de</a>
    </p>
    <p>
      <strong class="font-bold">Vertretungsberechtigter Vorstand:</strong>
    </p>
    <p>Michael Müller (1. Vorsitzender)<br> Martin Feller (stellv. Vorsitzender)</p>
    <p>Verantwortliche Stelle ist die natürliche oder juristische Person, die allein oder gemeinsam mit anderen über die
      Zwecke und Mittel der Verarbeitung von personenbezogenen Daten (z.&nbsp;B. Namen, E-Mail-Adressen o. Ä.)
      entscheidet.</p>

    <h3>
      <strong class="font-heading">Gesetzlich vorgeschriebener Datenschutz­beauftragter</strong>
    </h3>
    <p>Das Unternehmen ist nach Art. 37 Abs. 1 litt. b, c DSGVO i.V.m. § 38 Abs. 1 BDSG nicht verpflichtet einen
      Datenschutzbeauftragten zu benennen. Ansprechpartner ist der vertretungsberechtigte Vorstand.</p>
    <h2><strong class="font-heading">Begriffsbestimmungen</strong></h2>
    <p>Die Datenschutzerklärung beruht auf den Begrifflichkeiten, die durch den Europäischen Richtlinien- und
      Verordnungsgeber beim Erlass der Datenschutz-Grundverordnung (DS-GVO) verwendet wurden. Unsere
      Datenschutzerklärung soll sowohl für die Öffentlichkeit als auch für unsere Kunden und Geschäftspartner einfach
      lesbar und verständlich sein. Um dies zu gewährleisten, möchten wir vorab die verwendeten Begrifflichkeiten
      erläutern.</p>
    <p>Wir verwenden in dieser Datenschutzerklärung unter anderem die folgenden Begriffe:</p>
    <h3><strong class="font-heading">&nbsp;Personenbezogene Daten</strong></h3>
    <p>&nbsp;Personenbezogene Daten sind alle Informationen, die sich auf eine identifizierte oder identifizierbare
      natürliche Person beziehen. Als identifizierbar wird eine natürliche Person angesehen, die direkt oder indirekt,
      insbesondere mittels Zuordnung zu einer Kennung wie einem Namen, zu einer Kennnummer, zu Standortdaten, zu einer
      Online-Kennung oder zu einem oder mehreren besonderen Merkmalen, die Ausdruck der physischen, physiologischen,
      genetischen, psychischen, wirtschaftlichen, kulturellen oder sozialen Identität dieser natürlichen Person sind,
      identifiziert werden kann.</p>
    <h3>&nbsp;Betroffene Person</h3>
    <p>&nbsp;Betroffene Person ist jede identifizierte oder identifizierbare natürliche Person, deren personenbezogene
      Daten von dem für die Verarbeitung Verantwortlichen (unser Unternehmen) verarbeitet werden.</p>
    <h3><strong class="font-heading">&nbsp;Verarbeitung</strong></h3>
    <p>&nbsp;Verarbeitung ist jeder mit oder ohne Hilfe automatisierter Verfahren ausgeführte Vorgang oder jede solche
      Vorgangsreihe im Zusammenhang mit personenbezogenen Daten wie das Erheben, das Erfassen, die Organisation, das
      Ordnen, die Speicherung, die Anpassung oder Veränderung, das Auslesen, das Abfragen, die Verwendung, die
      Offenlegung durch Übermittlung, Verbreitung oder eine andere Form der Bereitstellung, den Abgleich oder die
      Verknüpfung, die Einschränkung, das Löschen oder die Vernichtung.</p>
    <h3><strong class="font-heading">&nbsp;Einschränkung der Verarbeitung</strong></h3>
    <p>&nbsp;Einschränkung der Verarbeitung ist die Markierung gespeicherter personenbezogener Daten mit dem Ziel, ihre
      künftige Verarbeitung einzuschränken.</p>
    <h3><strong class="font-heading">&nbsp;Profiling</strong></h3>
    <p>&nbsp;Profiling ist jede Art der automatisierten Verarbeitung personenbezogener Daten, die darin besteht, dass
      diese personenbezogenen Daten verwendet werden, um bestimmte persönliche Aspekte, die sich auf eine natürliche
      Person beziehen, zu bewerten, insbesondere, um Aspekte bezüglich Arbeitsleistung, wirtschaftlicher Lage,
      Gesundheit, persönlicher Vorlieben, Interessen, Zuverlässigkeit, Verhalten, Aufenthaltsort oder Ortswechsel dieser
      natürlichen Person zu analysieren oder vorherzusagen.</p>
    <h3><strong class="font-heading">&nbsp;Pseudonymisierung</strong></h3>
    <p>&nbsp;Pseudonymisierung ist die Verarbeitung personenbezogener Daten in einer Weise, auf welche die
      personenbezogenen Daten ohne Hinzuziehung zusätzlicher Informationen nicht mehr einer spezifischen betroffenen
      Person zugeordnet werden können, sofern diese zusätzlichen Informationen gesondert aufbewahrt werden und
      technischen und organisatorischen Maßnahmen unterliegen, die gewährleisten, dass die personenbezogenen Daten nicht
      einer identifizierten oder identifizierbaren natürlichen Person zugewiesen werden.</p>
    <h3><strong class="font-heading">&nbsp;Auftragsverarbeiter</strong></h3>
    <p>Auftragsverarbeiter ist eine natürliche oder juristische Person, Behörde, Einrichtung oder andere Stelle, die
      personenbezogene Daten im Auftrag des Verantwortlichen verarbeitet.</p>
    <h3><strong class="font-heading">&nbsp;Empfänger</strong></h3>
    <p>Empfänger ist eine natürliche oder juristische Person, Behörde, Einrichtung oder andere Stelle, der
      personenbezogene Daten offengelegt werden, unabhängig davon, ob es sich bei ihr um einen Dritten handelt oder
      nicht. Behörden, die im Rahmen eines bestimmten Untersuchungsauftrags nach dem Unionsrecht oder dem Recht der
      Mitgliedstaaten möglicherweise personenbezogene Daten erhalten, gelten jedoch nicht als Empfänger.</p>
    <h3><strong class="font-heading">&nbsp;Dritter</strong></h3>
    <p>Dritter ist eine natürliche oder juristische Person, Behörde, Einrichtung oder andere Stelle außer der
      betroffenen Person, dem Verantwortlichen, dem Auftragsverarbeiter und den Personen, die unter der unmittelbaren
      Verantwortung des Verantwortlichen oder des Auftragsverarbeiters befugt sind, die personenbezogenen Daten zu
      verarbeiten.</p>
    <h3><strong class="font-heading">&nbsp;Einwilligung</strong></h3>
    <p>Einwilligung ist jede von der betroffenen Person freiwillig für den bestimmten Fall in informierter Weise und
      unmissverständlich abgegebene Willensbekundung in Form einer Erklärung oder einer sonstigen eindeutigen
      bestätigenden Handlung, mit der die betroffene Person zu verstehen gibt, dass sie mit der Verarbeitung der sie
      betreffenden personenbezogenen Daten einverstanden ist.</p>
    <h2><strong class="font-heading">Rechtliche Rahmenbedingungen</strong></h2>
    <h3><strong class="font-heading">Rechtsgrundlage der Verarbeitung</strong></h3>
    <p>Art. 6 Abs. 1 lit. a DS-GVO dient unserem Unternehmen als Rechtsgrundlage für Verarbeitungsvorgänge, bei denen
      wir eine Einwilligung für einen bestimmten Verarbeitungszweck einholen.</p>
    <p>Ist die Verarbeitung personenbezogener Daten zur Erfüllung eines Vertrags, dessen Vertragspartei Sie sind,
      erforderlich, wie dies beispielsweise bei Verarbeitungsvorgängen der Fall ist, die für eine Lieferung von Waren
      oder die Erbringung einer sonstigen Leistung oder Gegenleistung notwendig sind, so beruht die Verarbeitung auf
      Art. 6 Abs. 1 lit. b DS-GVO. Gleiches gilt für solche Verarbeitungsvorgänge die zur Durchführung vorvertraglicher
      Maßnahmen erforderlich sind, etwa in Fällen von Anfragen zu unseren Produkten oder Leistungen.</p>
    <p>Unterliegt unser Unternehmen einer rechtlichen Verpflichtung durch welche eine Verarbeitung von personenbezogenen
      Daten erforderlich wird, wie beispielsweise zur Erfüllung steuerlicher Pflichten, so basiert die Verarbeitung auf
      Art. 6 Abs. 1 lit. c DS-GVO.</p>
    <p>In seltenen Fällen könnte die Verarbeitung von personenbezogenen Daten erforderlich werden, um lebenswichtige
      Interessen der betroffenen Person oder einer anderen natürlichen Person zu schützen. Dies wäre beispielsweise der
      Fall, wenn ein Besucher in unserem Betrieb verletzt werden würde und daraufhin sein Name, sein Alter, seine
      Krankenkassendaten oder sonstige lebenswichtige Informationen an einen Arzt, ein Krankenhaus oder sonstige Dritte
      weitergegeben werden müssten. Dann würde die Verarbeitung auf Art. 6 Abs. 1 lit. d DS-GVO beruhen.</p>
    <p>Letztlich könnten Verarbeitungsvorgänge auf Art. 6 Abs. 1 lit. f DS-GVO beruhen. Auf dieser Rechtsgrundlage
      basieren Verarbeitungsvorgänge, die von keiner der vorgenannten Rechtsgrundlagen erfasst werden, wenn die
      Verarbeitung zur Wahrung eines berechtigten Interesses unseres Unternehmens oder eines Dritten erforderlich ist,
      sofern die Interessen, Grundrechte und Grundfreiheiten des Betroffenen nicht überwiegen. Solche
      Verarbeitungsvorgänge sind uns insbesondere deshalb gestattet, weil sie durch den Europäischen Gesetzgeber
      besonders erwähnt wurden. Er vertrat insoweit die Auffassung, dass ein berechtigtes Interesse anzunehmen sein
      könnte, wenn Sie ein Kunde unseres Unternehmens sind (Erwägungsgrund 47 Satz 2 DS-GVO).</p>
    <h3><strong class="font-heading">Hinweis zur Datenweitergabe in die USA</strong></h3>
    <p>Auf unserer Webseite sind unter anderem Tools von Unternehmen mit Sitz in den USA eingebunden. Wenn diese Tools
      aktiv sind, können Ihre personenbezogenen Daten an die US-Server der jeweiligen Unternehmen weitergegeben werden.
      Wir weisen darauf hin, dass die USA kein sicherer Drittstaat im Sinne des EU-Datenschutzrechts sind.
      US-Unternehmen sind dazu verpflichtet, personenbezogene Daten an Sicherheitsbehörden herauszugeben, ohne dass Sie
      als Betroffener hiergegen gerichtlich vorgehen könnten. Es kann daher nicht ausgeschlossen werden, dass
      US-Behörden (z.B. Geheimdienste) Ihre auf US-Servern befindlichen Daten zu Überwachungszwecken verarbeiten,
      auswerten und dauerhaft speichern. Wir haben auf diese Verarbeitungstätigkeiten keinen Einfluss.</p>
    <h3>Speicherdauer</h3>
    <p>Soweit innerhalb dieser Datenschutzerklärung keine speziellere Speicherdauer genannt wurde, verbleiben Ihre
      personenbezogenen Daten bei uns, bis der Zweck für die Datenverarbeitung entfällt. Wenn Sie ein berechtigtes
      Löschersuchen geltend machen oder eine Einwilligung zur Datenverarbeitung widerrufen, werden Ihre Daten gelöscht,
      sofern wir keine anderen rechtlich zulässigen Gründe für die Speicherung Ihrer personenbezogenen Daten haben (z.B.
      steuer- oder handelsrechtliche Aufbewahrungsfristen); im letztgenannten Fall erfolgt die Löschung nach Fortfall
      dieser Gründe.</p>
    <h2><strong class="font-heading">Hosting und Content Delivery Networks (CDN)</strong></h2>
    <h3><strong class="font-heading">Externes Hosting</strong></h3>
    <p>Diese Website wird bei einem externen Dienstleister gehostet (Hoster). Die personenbezogenen Daten, die auf
      dieser Website erfasst werden, werden auf den Servern des Hosters gespeichert. Hierbei kann es sich v. a. um
      IP-Adressen, Kontaktanfragen, Meta- und Kommunikationsdaten, Vertragsdaten, Kontaktdaten, Namen, Websitezugriffe
      und sonstige Daten, die über eine Website generiert werden, handeln.</p>
    <p>Der Einsatz des Hosters erfolgt zum Zwecke der Vertragserfüllung gegenüber unseren potenziellen und bestehenden
      Kunden (Art. 6 Abs. 1 lit. b DSGVO) und im Interesse einer sicheren, schnellen und effizienten Bereitstellung
      unseres Online-Angebots durch einen professionellen Anbieter (Art. 6 Abs. 1 lit. f DSGVO).</p>
    <p>Unser Hoster wird Ihre Daten nur insoweit verarbeiten, wie dies zur Erfüllung seiner Leistungspflichten
      erforderlich ist und unsere Weisungen in Bezug auf diese Daten befolgen.</p>
    <p>Wir setzen folgenden Hoster ein:</p>
    <p>Artfiles New Media GmbH</p>
    <p>Zirkusweg 1</p>
    <p>D-20359 Hamburg</p>
    <p>T <a href="tel:+494032027290">040 320 272 90</a></p>
    <p>M <a href="mailto:info@artfiles.de">info@artfiles.de</a></p>
    <h4><strong class="font-heading">Abschluss eines Vertrages über Auftragsverarbeitung</strong></h4>
    <p>Um die datenschutzkonforme Verarbeitung zu gewährleisten, haben wir einen Vertrag über Auftragsverarbeitung mit
      unserem Hoster geschlossen.</p>
    <p><strong>Typo 3</strong></p>
    <p>Wir verwenden Typo3 als Redaktionssystem für unsere Webseite. Typo3 verwendet funktionale (notwendige) Cookies,
      um den Anmeldeprozess für Redakteure und Administratoren zu gewährleisten.<br> Insbesondere wird beim Versuch,
      sich an die Administrationsoberfläche von Typo3 anzumelden, ein Cookie gesetzt. Dieses Cookie wird ausschließlich
      für die aktive Sitzung verwendet und wird gelöscht, sobald Sie den Browser schließen. Das Cookie wird nicht zur
      Auswertung von Nutzern verwendet.</p>
    <p><strong>Cloudflare</strong></p>
    <p>Auf unserer Website verwenden wir ein sog. Content Delivery Network ("CDN") des Technologiedienstleisters
      Cloudflare Inc., 101 Townsend St. San Francisco, CA 94107, USA ("Cloudflare"). Bei einem Content Delivery Network
      handelt es sich um einen Online-Dienst, mit dessen Hilfe insbesondere große Mediendateien (wie z.B. Grafiken,
      Seiteninhalte oder Skripte) durch ein Netz regional verteilter und über das Internet verbundener Server
      ausgeliefert werden. Der Einsatz des Content Delivery Network von Cloudflare hilft uns bei der Optimierung der
      Ladegeschwindigkeiten unserer Website.</p>
    <p>Die Verarbeitung erfolgt gemäß Art. 6 Abs. 1 lit. f DSGVO auf Basis unseres berechtigten Interesses an einer
      sicheren und effizienten Bereitstellung, sowie Verbesserung der Stabilität und Funktionalität unserer Website.</p>
    <p></p>
    <p>Wir haben mit Cloudfare einen Auftragsverarbeitungsvertrag (Data Processing Addendum, einsehbar unter <a
        href="https://www.cloudflare.com/media/pdf/cloudflare-customer-dpa.pdf" target="_blank" rel="noreferrer">www.cloudflare.com/media/pdf/cloudflare-customer-dpa.pdf</a>)
      abgeschlossen, mit dem Cloudfare verpflichtet wird, die Daten unserer Seitenbesucher zu schützen und sie nicht an
      Dritte weiter zu geben. Für die Übermittlung von Daten aus der EU in die USA beruft sich Cloudfare hierbei auf
      sog. Standarddatenschutzklauseln der Europäischen Kommission, welche die Einhaltung des europäischen
      Datenschutzniveaus in den USA gewährleisten sollen.</p>
    <p></p>
    <p>Weitere Informationen finden Sie in der Datenschutzerklärung von Cloudflare unter: <a
        href="https://www.cloudflare.com/privacypolicy/" target="_blank" rel="noreferrer">www.cloudflare.com/privacypolicy/</a>
    </p>
    <h2><strong class="font-heading">Sicherheit</strong></h2>
    <h3><strong class="font-heading">Sicherheitshinweis</strong></h3>
    <p>Wir sichern unsere Webseite und sonstige IT-Systeme durch geeignete technische und organisatorische Maßnahmen
      gegen Verlust, Zerstörung, unberechtigten Zugriff, unberechtigte Veränderung oder unberechtigte Verbreitung Ihrer
      Daten ab. Ein vollständiger Schutz gegen alle Gefahren ist praktisch trotz aller Sorgfalt jedoch nicht in jedem
      Fall möglich. Weil bei der Kommunikation per E-Mail die vollständige Datensicherheit von uns nicht gewährleistet
      werden kann, empfehlen wir zur Übermittlung von vertraulichen Informationen den Postweg.</p>
    <h2><strong class="font-heading">SSL- bzw. TLS-Verschlüsselung</strong></h2>
    <p>Diese Seite nutzt aus Sicherheitsgründen und zum Schutz der Übertragung vertraulicher Inhalte, wie zum Beispiel
      Bestellungen oder Anfragen, die Sie an uns als Seitenbetreiber senden, eine SSL- bzw. TLS-Verschlüsselung. Eine
      verschlüsselte Verbindung erkennen Sie daran, dass die Adresszeile des Browsers von „http://“ auf „https://“
      wechselt und an dem Schloss-Symbol in Ihrer Browserzeile.</p>
    <p>Wenn die SSL- bzw. TLS-Verschlüsselung aktiviert ist, können die Daten, die Sie an uns übermitteln, nicht von
      Dritten mitgelesen werden.</p>
    <h2><strong class="font-heading">Ihre Rechte</strong></h2>
    <h3><strong class="font-heading">Widerruf Ihrer Einwilligung zur Datenverarbeitung</strong></h3>
    <p>Viele Datenverarbeitungsvorgänge sind nur mit Ihrer ausdrücklichen Einwilligung möglich. Sie können eine bereits
      erteilte Einwilligung jederzeit widerrufen. Die Rechtmäßigkeit der bis zum Widerruf erfolgten Datenverarbeitung
      bleibt vom Widerruf unberührt.</p>
    <h3><strong class="font-heading">Widerspruchsrecht gegen die Datenerhebung in besonderen Fällen sowie gegen Direktwerbung (Art. 21 DSGVO)</strong></h3>
    <p><strong>WENN DIE DATENVERARBEITUNG AUF GRUNDLAGE VON ART. 6 ABS. 1 LIT. E ODER F DSGVO ERFOLGT, HABEN SIE
      JEDERZEIT DAS RECHT, AUS GRÜNDEN, DIE SICH AUS IHRER BESONDEREN SITUATION ERGEBEN, GEGEN DIE VERARBEITUNG IHRER
      PERSONENBEZOGENEN DATEN WIDERSPRUCH EINZULEGEN; DIES GILT AUCH FÜR EIN AUF DIESE BESTIMMUNGEN GESTÜTZTES
      PROFILING. DIE JEWEILIGE RECHTSGRUNDLAGE, AUF DENEN EINE VERARBEITUNG BERUHT, ENTNEHMEN SIE DIESER
      DATENSCHUTZERKLÄRUNG. WENN SIE WIDERSPRUCH EINLEGEN, WERDEN WIR IHRE BETROFFENEN PERSONENBEZOGENEN DATEN NICHT
      MEHR VERARBEITEN, ES SEI DENN, WIR KÖNNEN ZWINGENDE SCHUTZWÜRDIGE GRÜNDE FÜR DIE VERARBEITUNG NACHWEISEN, DIE IHRE
      INTERESSEN, RECHTE UND FREIHEITEN ÜBERWIEGEN ODER DIE VERARBEITUNG DIENT DER GELTENDMACHUNG, AUSÜBUNG ODER
      VERTEIDIGUNG VON RECHTSANSPRÜCHEN (WIDERSPRUCH NACH ART. 21 ABS. 1 DSGVO).</strong></p>
    <p><strong>WERDEN IHRE PERSONENBEZOGENEN DATEN VERARBEITET, UM DIREKTWERBUNG ZU BETREIBEN, SO HABEN SIE DAS RECHT,
      JEDERZEIT WIDERSPRUCH GEGEN DIE VERARBEITUNG SIE BETREFFENDER PERSONENBEZOGENER DATEN ZUM ZWECKE DERARTIGER
      WERBUNG EINZULEGEN; DIES GILT AUCH FÜR DAS PROFILING, SOWEIT ES MIT SOLCHER DIREKTWERBUNG IN VERBINDUNG STEHT.
      WENN SIE WIDERSPRECHEN, WERDEN IHRE PERSONENBEZOGENEN DATEN ANSCHLIESSEND NICHT MEHR ZUM ZWECKE DER DIREKTWERBUNG
      VERWENDET (WIDERSPRUCH NACH ART. 21 ABS. 2 DSGVO).</strong></p>
    <h3><strong class="font-heading">Beschwerderecht bei einer Aufsichtsbehörde</strong></h3>
    <p>Im Falle von Verstößen gegen die DSGVO steht den Betroffenen ein Beschwerderecht bei einer Aufsichtsbehörde,
      insbesondere in dem Mitgliedstaat ihres gewöhnlichen Aufenthalts, ihres Arbeitsplatzes oder des Orts des
      mutmaßlichen Verstoßes zu. Das Beschwerderecht besteht unbeschadet anderweitiger verwaltungsrechtlicher oder
      gerichtlicher Rechtsbehelfe.</p>
    <h3><strong class="font-heading">Recht auf Datenübertragbarkeit</strong></h3>
    <p>Sie haben das Recht, Daten, die wir auf Grundlage Ihrer Einwilligung oder in Erfüllung eines Vertrags
      automatisiert verarbeiten, an sich oder an einen Dritten in einem gängigen, maschinenlesbaren Format aushändigen
      zu lassen. Sofern Sie die direkte Übertragung der Daten an einen anderen Verantwortlichen verlangen, erfolgt dies
      nur, soweit es technisch machbar ist.</p>
    <h3><strong class="font-heading">Auskunft, Löschung und Berichtigung</strong></h3>
    <p>Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen jederzeit das Recht auf unentgeltliche Auskunft über
      Ihre gespeicherten personenbezogenen Daten, deren Herkunft und Empfänger und den Zweck der Datenverarbeitung und
      ggf. ein Recht auf Berichtigung oder Löschung dieser Daten. Hierzu sowie zu weiteren Fragen zum Thema
      personenbezogene Daten können Sie sich jederzeit an uns wenden.</p>
    <h3><strong class="font-heading">Recht auf Einschränkung der Verarbeitung</strong></h3>
    <p>Sie haben das Recht, die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen. Hierzu können
      Sie sich jederzeit an uns wenden. Das Recht auf Einschränkung der Verarbeitung besteht in folgenden Fällen:</p>
    <p>Wenn Sie die Richtigkeit Ihrer bei uns gespeicherten personenbezogenen Daten bestreiten, benötigen wir in der
      Regel Zeit, um dies zu überprüfen. Für die Dauer der Prüfung haben Sie das Recht, die Einschränkung der
      Verarbeitung Ihrer personenbezogenen Daten zu verlangen.</p>
    <p>Wenn die Verarbeitung Ihrer personenbezogenen Daten unrechtmäßig geschah/geschieht, können Sie statt der Löschung
      die Einschränkung der Datenverarbeitung verlangen.</p>
    <p>Wenn wir Ihre personenbezogenen Daten nicht mehr benötigen, Sie sie jedoch zur Ausübung, Verteidigung oder
      Geltendmachung von Rechtsansprüchen benötigen, haben Sie das Recht, statt der Löschung die Einschränkung der
      Verarbeitung Ihrer personenbezogenen Daten zu verlangen.</p>
    <p>Wenn Sie einen Widerspruch nach Art. 21 Abs. 1 DSGVO eingelegt haben, muss eine Abwägung zwischen Ihren und
      unseren Interessen vorgenommen werden. Solange noch nicht feststeht, wessen Interessen überwiegen, haben Sie das
      Recht, die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.</p>
    <p>Wenn Sie die Verarbeitung Ihrer personenbezogenen Daten eingeschränkt haben, dürfen diese Daten – von ihrer
      Speicherung abgesehen – nur mit Ihrer Einwilligung oder zur Geltendmachung, Ausübung oder Verteidigung von
      Rechtsansprüchen oder zum Schutz der Rechte einer anderen natürlichen oder juristischen Person oder aus Gründen
      eines wichtigen öffentlichen Interesses der Europäischen Union oder eines Mitgliedstaats verarbeitet werden.</p>
    <h2><strong class="font-heading">Datenerfassung auf dieser Website</strong></h2>
    <h3><strong class="font-heading">Server-Log-Dateien</strong></h3>
    <p>Der Provider der Seiten erhebt und speichert automatisch Informationen in so genannten Server-Log-Dateien, die
      Ihr Browser automatisch an uns übermittelt. Dies sind:</p>
    <ul>
      <li>Browsertyp und Browserversion</li>
      <li>verwendetes Betriebssystem</li>
      <li>Referrer URL</li>
      <li>Hostname des zugreifenden Rechners</li>
      <li>Uhrzeit der Serveranfrage</li>
      <li>IP-Adresse</li>
    </ul>
    <p>Eine Zusammenführung dieser Daten mit anderen Datenquellen wird nicht vorgenommen.</p>
    <p>Die Erfassung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Der Websitebetreiber hat ein
      berechtigtes Interesse an der technisch fehlerfreien Darstellung und der Optimierung seiner Website – hierzu
      müssen die Server-Log-Files erfasst werden.</p>
    <h3>Anfrage per E-Mail, Telefon oder Telefax</h3>
    <p>Wenn Sie uns per E-Mail, Telefon oder Telefax kontaktieren, wird Ihre Anfrage inklusive aller daraus
      hervorgehenden personenbezogenen Daten (Name, Anfrage) zum Zwecke der Bearbeitung Ihres Anliegens bei uns
      gespeichert und verarbeitet. Diese Daten geben wir nicht ohne Ihre Einwilligung weiter.</p>
    <p>Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit. b DSGVO, sofern Ihre Anfrage mit der
      Erfüllung eines Vertrags zusammenhängt oder zur Durchführung vorvertraglicher Maßnahmen erforderlich ist. In allen
      übrigen Fällen beruht die Verarbeitung auf unserem berechtigten Interesse an der effektiven Bearbeitung der an uns
      gerichteten Anfragen (Art. 6 Abs. 1 lit. f DSGVO) oder auf Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO) sofern
      diese abgefragt wurde.</p>
    <p>Die von Ihnen an uns per Kontaktanfragen übersandten Daten verbleiben bei uns, bis Sie uns zur Löschung
      auffordern, Ihre Einwilligung zur Speicherung widerrufen oder der Zweck für die Datenspeicherung entfällt (z.&nbsp;B.
      nach abgeschlossener Bearbeitung Ihres Anliegens). Zwingende gesetzliche Bestimmungen – insbesondere gesetzliche
      Aufbewahrungsfristen – bleiben unberührt.</p>
    <h3><strong class="font-heading">Bewerbungen</strong></h3>
    <p>Wir können es nicht verhindern, dass Sie sich bei uns bewerben (z. B. per E-Mail oder postalisch). Im Folgenden
      informieren wir Sie über Umfang, Zweck und Verwendung Ihrer im Rahmen des Bewerbungsprozesses erhobenen/freiwillig
      mitgeteilten personenbezogenen Daten. Wir versichern, dass die Erhebung, Verarbeitung und Nutzung Ihrer Daten in
      Übereinstimmung mit geltendem Datenschutzrecht und allen weiteren gesetzlichen Bestimmungen erfolgt und Ihre Daten
      streng vertraulich behandelt werden.</p>
    <h4><strong class="font-heading">Umfang und Zweck der Datenerhebung</strong></h4>
    <p>Wenn Sie uns eine Bewerbung zukommen lassen, verarbeiten wir Ihre damit verbundenen personenbezogenen Daten (z.
      B. Kontakt- und Kommunikationsdaten, Bewerbungsunterlagen, Notizen im Rahmen von Bewerbungsgesprächen etc.),
      soweit dies zur Entscheidung über die Begründung eines Beschäftigungsverhältnisses erforderlich ist.
      Rechtsgrundlage hierfür ist § 26 BDSG nach deutschem Recht (Anbahnung eines Beschäftigungsverhältnisses), Art. 6
      Abs. 1 lit. b DSGVO (allgemeine Vertragsanbahnung) und – sofern Sie eine Einwilligung erteilt haben – Art. 6 Abs.
      1 lit. a DSGVO. Die Einwilligung ist jederzeit widerrufbar. Ihre personenbezogenen Daten werden innerhalb unseres
      Unternehmens ausschließlich an Personen weitergegeben, die an der Bearbeitung Ihrer Bewerbung beteiligt sind.</p>
    <p>Sofern die Bewerbung erfolgreich ist, werden die von Ihnen eingereichten Daten auf Grundlage von § 26 BDSG und
      Art. 6 Abs. 1 lit. b DSGVO zum Zwecke der Durchführung des Beschäftigungsverhältnisses in unseren
      Datenverarbeitungssystemen gespeichert.</p>
    <h4><strong class="font-heading">Aufbewahrungsdauer der Daten</strong></h4>
    <p>Wenn wir Ihnen kein Stellenangebot machen können, Sie ein Stellenangebot ablehnen, Ihre Bewerbung zurückziehen,
      Ihre Einwilligung zur Datenverarbeitung widerrufen oder uns zur Löschung der Daten auffordern, werden die von
      Ihnen übermittelten Daten inkl. ggf. verbleibender physischer Bewerbungsunterlagen für maximal 6 Monate nach
      Abschluss des Bewerbungsverfahrens gespeichert bzw. aufbewahrt (Aufbewahrungsfrist), um die Einzelheiten des
      Bewerbungsprozesses im Falle von Unstimmigkeiten nachvollziehen zu können (Art. 6 Abs. 1 lit. f DSGVO).</p>
    <p><strong>DIESER SPEICHERUNG KÖNNEN SIE WIDERSPRECHEN, SOFERN IHRERSEITS BERECHTIGTE INTERESSEN VORLIEGEN, DIE
      UNSERE INTERESSEN ÜBERWIEGEN.</strong></p>
    <p>Nach Ablauf der Aufbewahrungsfrist werden die Daten gelöscht, sofern keine gesetzliche Aufbewahrungspflicht oder
      ein sonstiger Rechtsgrund zur weiteren Speicherung vorliegt. Sofern ersichtlich ist, dass die Aufbewahrung Ihrer
      Daten nach Ablauf der Aufbewahrungsfrist erforderlich sein wird (z. B. aufgrund eines drohenden oder anhängigen
      Rechtsstreits), findet eine Löschung erst statt, wenn die Daten gegenstandslos geworden sind. Sonstige gesetzliche
      Aufbewahrungspflichten bleiben unberührt.</p>
    <p><strong>Sofern keine Stellenausschreibung auf der Webseite oder anderen bekannten Bewerbungsportalen
      ausgeschrieben wurde, werden Ihre eingegangenen Unterlagen ungeöffnet vernichtet. Es erfolgt keinerlei
      Bearbeitung, Verarbeitung nach Öffnen der Unterlagen oder eine Rücksendung.</strong></p>
    <h3><strong class="font-heading">Links zu anderen Webseiten</strong></h3>
    <p>Unsere Website/App kann von Zeit zu Zeit Links zu Websites Dritter oder zu anderen Websites von uns selbst
      enthalten. Wenn Sie einem Link zu einer dieser Websites folgen, weisen wir Sie darauf hin, dass diese Websites
      ihre eigenen Datenschutzrichtlinien haben und dass wir für diese Richtlinien keine Verantwortung oder Haftung
      übernehmen. Bitte überprüfen Sie diese Datenschutzrichtlinien, bevor Sie personenbezogenen Daten an diese Websites
      weitergeben.</p>
    <p>&nbsp;Auf dieser Webseite werden Verweise auf Webseiten Dritter in Form sogenannter Links bzw. Verlinkungen
      angeboten. Erst wenn Sie auf einen solchen Link klicken, werden Daten zum Linkziel übertragen. Dies ist technisch
      notwendig. Die übertragenen Daten sind insbesondere: Ihre IP-Adresse, der Zeitpunkt zu dem Sie den Link angeklickt
      haben, die Seite auf der Sie den Link angeklickt haben, Angaben zu Ihrem Internet-Browser. Wenn Sie nicht möchten,
      dass diese Daten zum Linkziel übertragen werden, klicken Sie den Link nicht an.</p>
    <h3><strong class="font-heading">Unsere Social–Media–Auftritte</strong></h3>
    <h4><strong class="font-heading">Datenverarbeitung durch soziale Netzwerke</strong></h4>
    <p>Wir unterhalten öffentlich zugängliche Profile in sozialen Netzwerken. Die im Einzelnen von uns genutzten
      sozialen Netzwerke finden Sie weiter unten.</p>
    <p>Soziale Netzwerke wie Facebook, Twitter etc. können Ihr Nutzerverhalten in der Regel umfassend analysieren, wenn
      Sie deren Website oder eine Website mit integrierten Social-Media-Inhalten (z.&nbsp;B. Like-Buttons oder
      Werbebannern) besuchen. Durch den Besuch unserer Social-Media-Präsenzen werden zahlreiche datenschutzrelevante
      Verarbeitungsvorgänge ausgelöst. Im Einzelnen:</p>
    <p>Wenn Sie in Ihrem Social-Media-Account eingeloggt sind und unsere Social-Media-Präsenz besuchen, kann der
      Betreiber des Social-Media-Portals diesen Besuch Ihrem Benutzerkonto zuordnen. Ihre personenbezogenen Daten können
      unter Umständen aber auch dann erfasst werden, wenn Sie nicht eingeloggt sind oder keinen Account beim jeweiligen
      Social-Media-Portal besitzen. Diese Datenerfassung erfolgt in diesem Fall beispielsweise über Cookies, die auf
      Ihrem Endgerät gespeichert werden oder durch Erfassung Ihrer IP-Adresse.</p>
    <p>Mit Hilfe der so erfassten Daten können die Betreiber der Social-Media-Portale Nutzerprofile erstellen, in denen
      Ihre Präferenzen und Interessen hinterlegt sind. Auf diese Weise kann Ihnen interessenbezogene Werbung in- und
      außerhalb der jeweiligen Social-Media-Präsenz angezeigt werden. Sofern Sie über einen Account beim jeweiligen
      sozialen Netzwerk verfügen, kann die interessenbezogene Werbung auf allen Geräten angezeigt werden, auf denen Sie
      eingeloggt sind oder eingeloggt waren.</p>
    <p>Bitte beachten Sie außerdem, dass wir nicht alle Verarbeitungsprozesse auf den Social-Media-Portalen
      nachvollziehen können. Je nach Anbieter können daher ggf. weitere Verarbeitungsvorgänge von den Betreibern der
      Social-Media-Portale durchgeführt werden. Details hierzu entnehmen Sie den Nutzungsbedingungen und
      Datenschutzbestimmungen der jeweiligen Social-Media-Portale.</p>
    <h4><strong class="font-heading">Rechtsgrundlage</strong></h4>
    <p>Unsere Social-Media-Auftritte sollen eine möglichst umfassende Präsenz im Internet gewährleisten. Hierbei handelt
      es sich um ein berechtigtes Interesse im Sinne von Art. 6 Abs. 1 lit. f DSGVO. Die von den sozialen Netzwerken
      initiierten Analyseprozesse beruhen ggf. auf abweichenden Rechtsgrundlagen, die von den Betreibern der sozialen
      Netzwerke anzugeben sind (z.&nbsp;B. Einwilligung im Sinne des Art. 6 Abs. 1 lit. a DSGVO).</p>
    <h4><strong class="font-heading">Verantwortlicher und Geltendmachung von Rechten</strong></h4>
    <p>Wenn Sie einen unserer Social-Media-Auftritte (z.&nbsp;B. Facebook) besuchen, sind wir gemeinsam mit dem
      Betreiber der Social-Media-Plattform für die bei diesem Besuch ausgelösten Datenverarbeitungsvorgänge
      verantwortlich. Sie können Ihre Rechte (Auskunft, Berichtigung, Löschung, Einschränkung der Verarbeitung,
      Datenübertragbarkeit und Beschwerde) grundsätzlich sowohl ggü. uns als auch ggü. dem Betreiber des jeweiligen
      Social-Media-Portals (z.&nbsp;B. ggü. Facebook) geltend machen.</p>
    <p>Bitte beachten Sie, dass wir trotz der gemeinsamen Verantwortlichkeit mit den Social-Media-Portal-Betreibern
      nicht vollumfänglich Einfluss auf die Datenverarbeitungsvorgänge der Social-Media-Portale haben. Unsere
      Möglichkeiten richten sich maßgeblich nach der Unternehmenspolitik des jeweiligen Anbieters.</p>
    <h4><strong class="font-heading">Speicherdauer</strong></h4>
    <p>Die unmittelbar von uns über die Social-Media-Präsenz erfassten Daten werden von unseren Systemen gelöscht,
      sobald Sie uns zur Löschung auffordern, Ihre Einwilligung zur Speicherung widerrufen oder der Zweck für die
      Datenspeicherung entfällt. Gespeicherte Cookies verbleiben auf Ihrem Endgerät, bis Sie sie löschen. Zwingende
      gesetzliche Bestimmungen – insb. Aufbewahrungsfristen – bleiben unberührt.</p>
    <p>Auf die Speicherdauer Ihrer Daten, die von den Betreibern der sozialen Netzwerke zu eigenen Zwecken gespeichert
      werden, haben wir keinen Einfluss. Für Einzelheiten dazu informieren Sie sich bitte direkt bei den Betreibern der
      sozialen Netzwerke (z.&nbsp;B. in deren Datenschutzerklärung, siehe unten).</p>
    <h4><strong class="font-heading">Soziale Netzwerke im Einzelnen</strong></h4>
    <p><strong>Facebook Plugins (Like &amp; Share-Button)</strong></p>
    <p>Auf dieser Website sind Plugins des sozialen Netzwerks Facebook integriert. Anbieter dieses Dienstes ist die Meta
      Platforms Ireland Limited, 4 Grand Canal Square, Dublin 2, Irland. Die erfassten Daten werden nach Aussage von
      Facebook jedoch auch in die USA und in andere Drittländer übertragen.</p>
    <p>Die Facebook Plugins erkennen Sie an dem Facebook-Logo oder dem „Like-Button“ („Gefällt mir“) auf dieser Website.
      Eine Übersicht über die Facebook Plugins finden Sie hier: <a
          href="https://developers.facebook.com/docs/plugins/?locale=de_DE" target="_blank" rel="noreferrer">https://developers.facebook.com/docs/plugins/?locale=de_DE</a>.
    </p>
    <p>Wenn Sie diese Website besuchen, wird über das Plugin eine direkte Verbindung zwischen Ihrem Browser und dem
      Facebook-Server hergestellt. Facebook erhält dadurch die Information, dass Sie mit Ihrer IP-Adresse diese Website
      besucht haben. Wenn Sie den Facebook „Like-Button“ anklicken, während Sie in Ihrem Facebook-Account eingeloggt
      sind, können Sie die Inhalte dieser Website auf Ihrem Facebook-Profil verlinken. Dadurch kann Facebook den Besuch
      dieser Website Ihrem Benutzerkonto zuordnen. Wir weisen darauf hin, dass wir als Anbieter der Seiten keine
      Kenntnis vom Inhalt der übermittelten Daten sowie deren Nutzung durch Facebook erhalten. Weitere Informationen
      hierzu finden Sie in der Datenschutzerklärung von Facebook unter: <a
          href="https://de-de.facebook.com/privacy/explanation" target="_blank" rel="noreferrer">https://de-de.facebook.com/privacy/explanation</a>.
    </p>
    <p>Wenn Sie nicht wünschen, dass Facebook den Besuch dieser Website Ihrem Facebook-Nutzerkonto zuordnen kann, loggen
      Sie sich bitte aus Ihrem Facebook-Benutzerkonto aus.</p>
    <p>Die Verwendung der Facebook Plugins erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Der Websitebetreiber
      hat ein berechtigtes Interesse an einer möglichst umfangreichen Sichtbarkeit in den Sozialen Medien. Sofern eine
      entsprechende Einwilligung abgefragt wurde, erfolgt die Verarbeitung ausschließlich auf Grundlage von Art. 6 Abs.
      1 lit. a DSGVO und § 25 Abs. 1 TTDSG, soweit die Einwilligung die Speicherung von Cookies oder den Zugriff auf
      Informationen im Endgerät des Nutzers (z.&nbsp;B. Device-Fingerprinting) im Sinne des TTDSG umfasst. Die
      Einwilligung ist jederzeit widerrufbar.</p>
    <p>Soweit mit Hilfe des hier beschriebenen Tools personenbezogene Daten auf unserer Website erfasst und an Facebook
      weitergeleitet werden, sind wir und die Meta Platforms Ireland Limited, 4 Grand Canal Square, Grand Canal Harbour,
      Dublin 2, Irland gemeinsam für diese Datenverarbeitung verantwortlich (Art. 26 DSGVO). Die gemeinsame
      Verantwortlichkeit beschränkt sich dabei ausschließlich auf die Erfassung der Daten und deren Weitergabe an
      Facebook. Die nach der Weiterleitung erfolgende Verarbeitung durch Facebook ist nicht Teil der gemeinsamen
      Verantwortung. Die uns gemeinsam obliegenden Verpflichtungen wurden in einer Vereinbarung über gemeinsame
      Verarbeitung festgehalten. Den Wortlaut der Vereinbarung finden Sie unter: <a
          href="https://www.facebook.com/legal/controller_addendum" target="_blank" rel="noreferrer">https://www.facebook.com/legal/controller_addendum</a>.
      Laut dieser Vereinbarung sind wir für die Erteilung der Datenschutzinformationen beim Einsatz des Facebook-Tools
      und für die datenschutzrechtlich sichere Implementierung des Tools auf unserer Website verantwortlich. Für die
      Datensicherheit der Facebook-Produkte ist Facebook verantwortlich. Betroffenenrechte (z.&nbsp;B.
      Auskunftsersuchen) hinsichtlich der bei Facebook verarbeiteten Daten können Sie direkt bei Facebook geltend
      machen. Wenn Sie die Betroffenenrechte bei uns geltend machen, sind wir verpflichtet, diese an Facebook
      weiterzuleiten.</p>
    <p>Die Datenübertragung in die USA wird auf die Standardvertragsklauseln der EU-Kommission gestützt. Details finden
      Sie hier: <a href="https://www.facebook.com/legal/EU_data_transfer_addendum" target="_blank" rel="noreferrer">https://www.facebook.com/legal/EU_data_transfer_addendum</a>,
      <a href="https://de-de.facebook.com/help/566994660333381" target="_blank" rel="noreferrer">https://de-de.facebook.com/help/566994660333381</a>
      und <a href="https://www.facebook.com/policy.php" target="_blank" rel="noreferrer">https://www.facebook.com/policy.php</a>.
    </p>
    <p><a href="https://www.facebook.com/policy.php" target="_blank" rel="noreferrer">https://www.facebook.com/policy.php</a>.
    </p>

    <p><strong>Tools von Drittanbietern</strong></p>
    <h5>OpenStreetMap</h5>
    <p>Diese Seite nutzt über eine API den Open-Source-Mapping-Dienst „OpenStreetMap“ der OpenStreetMap Foundation, St
      John’s Innovation Centre, Cowley Road, Cambridge, CB4 0WS, United Kingdom, um Ihnen den Standort unseres
      Unternehmens darzustellen und Ihnen die Anfahrt zu erleichtern.</p>
    <p>Damit Ihnen die die Kartendienste von OpenStreetMap zur Verfügung gestellt werden können, erhebt der Dienst bei
      Seitenaufruf Ihre IP-Adresse und übermittelt diese an einen Server von OpenStreetMap, wo sie gespeichert wird.</p>
    <p>Diese Datenverarbeitungen erfolgen gemäß Art. 6 Abs. 1 lit. f DSGVO auf Basis unseres berechtigten Interesses an
      einer ansprechenden Darstellung unserer Online-Angebote und der leichten Auffindbarkeit unseres Standortes.</p>
    <p>Wenn Sie mit der künftigen Übermittlung Ihrer Daten an OpenStreetMap nicht einverstanden sind, besteht die
      Möglichkeit, den Webdienst OpenStreetMap vollständig zu deaktivieren, indem Sie die Anwendung JavaScript in Ihrem
      Browser ausschalten. OpenStreetMap und damit auch die Kartenanzeige auf dieser Internetseite können dann nicht
      genutzt werden.</p>
    <p></p>
    <p>Unter der nachstehenden Internetadresse erhalten Sie weitere Informationen über die Datenschutzbestimmungen von
      OpenStreetMap: <a href="https://wiki.osmfoundation.org/wiki/Privacy_Policy" target="_blank" rel="noreferrer">wiki.osmfoundation.org/wiki/Privacy_Policy</a>
    </p>
    <p>Soweit rechtlich erforderlich, haben wir zur vorstehend dargestellten Verarbeitung Ihrer Daten Ihre Einwilligung
      gemäß Art. 6 Abs. 1 lit. a DSGVO eingeholt. Sie können Ihre erteilte Einwilligung jederzeit mit Wirkung für die
      Zukunft widerrufen. Um Ihren Widerruf auszuüben, befolgen Sie bitte die vorstehend geschilderte Möglichkeit zur
      Vornahme eines Widerspruchs.</p>
    <h3>Webanalyse-Software "AWStats"</h3>
    <p>Damit wir unser Online-Angebot für Sie verbessern können, nutzen wir die Analyse-Software AWStats.</p>
    <p>Dieses Analyse-Werkzeug nutzt die Log-Dateien, die durch Ihren Besuch unserer Webpräsenz auf dem Web-Server
      gespeichert werden. Welche Daten dabei gespeichert werden, können Sie dem Abschnitt "Server-Log-Dateien"
      entnehmen.</p>
    <p>In den Log-Dateien werden in der Regel auch die IP-Adressen abgelegt. IP-Adressen sind eindeutige Referenzen auf
      einen bestimmten Benutzer. Die IP-Adressen werden jedoch von Ihrem Internet-Dienste-Anbieter (Telekom, Vodafone
      oder andere) vergeben. Wir selbst könnten keinerlei Bezug zu Ihnen herstellen.</p>
    <p>Bei unseren Auswertungen werden keinerlei IP-Adressen berücksichtigt.</p>
    <p>Diese Daten werden von uns auch nicht genutzt, um sie mit anderen Daten zusammenzuführen oder Profile zu
      bilden.</p>
    <p>AWStats wird lokal bei unserem Hosting Anbieter betrieben und sendet keine Daten zu anderen Servern.</p>
    <h2>Änderungen dieser Datenschutzerklärung</h2>
    <p>Wir behalten uns vor, diese Datenschutzerklärung zu ändern, wenn sich die Rechtslage oder dieses Online-Angebot
      oder die Art der Datenerfassung ändert. Dies gilt jedoch nur bezüglich Erklärungen zur Datenverarbeitung. Sofern
      eine Einwilligung des Nutzers notwendig ist oder Bestandteile der Datenschutzerklärung eine Regelung des
      Vertragsverhältnisses mit Nutzern enthalten, erfolgt die Änderung der Datenschutzerklärung nur nach Zustimmung des
      Nutzers.</p>
    <h4>Diese Datenschutzerklärung wurde in Zusammenarbeit mit einem Datenschutzbeauftragten erstellt und enthält
      Elemente folgender Anbieter:&nbsp;</h4>
    <ul>
      <li>audatis Consulting GmbH,&nbsp; Leopoldstr. 2-8, 32051 Herford (audatis MANGER)</li>
      <li>IT-Recht Kanzlei, Aller Messeplatz 2, 80339 Müncheni</li>
      <li>eRecht24 Gmbh &amp; Co. KG, Lietzenburger Str. 94, 10719 Berlin</li>
      <li>IT Logic GmbH,&nbsp;An der Struth 25, 65510 Idstein</li>
    </ul>
  </div>
</template>

<script>
import NavbarComponent from "@/components/NavbarComponent.vue";

export default {
  name: "ImprintView",
  components: {
    NavbarComponent
  }
}
</script>

<style scoped>

</style>