<template>
  <navbar-component :search="true" :menu="true"></navbar-component>

  <div class="flex flex-col gap-4 mx-4 pb-4 pt-6 lg:mx-52 xl:mx-96">
    <!-- header -->
    <div class="flex flex-col gap-2 justify-center text-center">
      <div class="text-xl uppercase text-gray-600">Den Ort entdecken</div>
      <div class="font-heading text-3xl font-bold text-secondary">Rundgang</div>
    </div>

    <!-- listing -->
    <listing-component filter="/feature=city_walk"></listing-component>
  </div>

  <footer-component></footer-component>
</template>

<script>
import NavbarComponent from "@/components/NavbarComponent.vue";
import ListingComponent from "@/components/ListingComponent.vue";
import FooterComponent from "@/components/FooterComponent.vue";

export default {
  name: "RoundTripView",
  components: {
    FooterComponent,
    NavbarComponent,
    ListingComponent
  }
}
</script>

<style scoped>

</style>