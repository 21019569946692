<template>
  <!-- nav -->
  <navbar-component :search="true" :menu="true"></navbar-component>

  <div class="flex flex-col gap-4 mx-4 pb-4 lg:mx-52 xl:mx-96 pt-6">
    <iframe id="venus-module__boc_detail" width="100%" class="h-screen"
            :src="`https://module-pwa.heideregion-uelzen.de/detail/${id.includes('id=') ? id : 'id=' + id}`" allow='geolocation'></iframe>
  </div>

  <footer-component></footer-component>
</template>

<script>
import NavbarComponent from "@/components/NavbarComponent.vue";
import FooterComponent from "@/components/FooterComponent.vue";
export default {
  name: "DetailView",
  components: {
    FooterComponent,
    NavbarComponent
  },
  props: ['id']
}
</script>

<style scoped>

</style>